<template>
  <div class="demo-app">
    test
  </div>
  <!--  :selected-date="selectedDate"-->
</template>

<script>

import {mapGetters} from 'vuex';

export default {
  data: () => ({

  }),
  beforeCreate() {
    this.$store.dispatch('loyaltyStore/GET_COUNTRIES');
  },
  computed: {
    ...mapGetters({
      countriesList: 'loyaltyStore/COUNTRIES'
    })
  },
  watch: {
    countriesList: {
      handler(data) {
        console.log(data);
      },
      deep: true
    }
  },
  methods: {

  }
}
</script>

<style lang="scss" scoped>
.vuecal::v-deep {
  & .calendar-event-dot {
    font-size: 7px;
    position: absolute;
    top: 4px;
    left: 7px;
  }
}
</style>